@import "src/variables";

.RestorePage {
  display: grid;
  place-items: center;

  &-form {
    width: 480px;
    padding: 48px;
    background: $gray2;
    border: 1px solid $gray3;
    border-radius: 48px;
  }
}
