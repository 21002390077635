.Categories {
  &-categoriesSelect {
    display: grid;
    grid-gap: 0 10px;

    &.threeColumns {
      width: 600px;
      grid-template-columns: 1fr auto auto auto;
    }

    &.twoColumns {
      width: 400px;
      grid-template-columns: 1fr auto auto;
    }
  }

  &-addCategoryButton {
    margin-bottom: 24px;
    padding: 4px;

    & > span {
      text-decoration: underline;
    }
  }

  &-deleteItemButton {
    margin-top: 3px;
  }

}