@import "../../../../variables";

.ApplicationLayout {
  height: 100%;
  min-height: 0;
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-template-columns: 235px 1fr;
  place-items: stretch;

  &.twoColumn {
    grid-template-columns: 235px 1fr;
    transition: grid-template-columns 0.2s;
  }

  &.oneColumn {
    grid-template-columns: 0 1fr;
    transition: grid-template-columns 0.2s;
  }

  &-header {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &-menu {
    width: 235px;
    overflow-y: auto;
  }

  &-content {
    min-height: 0;
    overflow: auto;
    background: $gray1;
  }
}
