$gray1: #ffffff;
$gray2: #fafafa;
$gray3: #f5f5f5;
$gray4: #f0f0f0;
$gray5: #d9d9d9;
$gray6: #bfbfbf;
$gray7: #8c8c8c;
$gray8: #595959;
$gray9: #434343;
$gray10: #262626;
$gray11: #1f1f1f;
$gray12: #141414;
$gray13: #000000;

$blue1: #e6f7ff;
$blue2: #bae7ff;
$blue3: #91d5ff;
$blue4: #69c0ff;
$blue5: #40a9ff;
$blue6: #1890ff;
$blue7: #096dd9;
$blue8: #0050b3;
$blue9: #003a8c;
$blue10: #002766;

$blue-clickpass: #0b0b44;

$shade1: rgb(252, 253, 253);
$shade2: rgb(241, 243, 243);
$shade3: rgb(235, 237, 239);
$shade4: rgb(220, 222, 224);
$shade5: rgb(200, 204, 209);
$shade6: rgb(171, 179, 186);
$shade7: rgb(137, 143, 149);
$shade8: rgb(74, 75, 76);
$shade9: rgb(69, 77, 84);
$shade10: #0050b3;
$shade11: #003a8c;
$shade12: #002766;

$shade11: rgb(228, 237, 246);
$shade12: rgb(214, 230, 245);
$shade13: rgb(186, 212, 238);

:root {
  --clp-font-weight-normal: 400;
  --clp-font-weight-medium: 500;
  --clp-font-weight-semi-bold: 600;
  --clp-font-weight-bold: 700;
}
