.MultiTicketProductFormModal {
  &-error {
    color: red;
  }

  &-componentTable {
    width: 100%;
    margin-bottom: 20px;

    & tbody td {
      vertical-align: top;
      padding-top: 10px;
    }
  }

  &-componentName {
    padding-right: 20px;
  }

  &-componentSelect {
    width: 100%;
  }

  &-deleteComponentButton {
    text-align: right;
  }
}
