@import "src/variables";

.Header {
  background-color: $shade5;
  line-height: 39px;
  color: $shade9;
  display: grid;
  grid-template-columns: 1fr auto;

  &-title {
    margin-left: 12px;
  }

  &-user {
    float: right;
    margin-right: 12px;
  }

  &-signOut {
    color: $shade9;
    text-decoration: underline;
    margin-left: 12px;

    &:hover {
      color: black;
    }
  }
}
