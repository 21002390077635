.Balance {
  margin: 0 30px;
  height: calc(100vh - 86px);
  overflow: auto;

  &-form {
    margin-top: 10px;
  }

  &-numberInput {
    width: 200px;
  }
}
