@import "src/variables";

.NonAuthLayout {
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  place-items: stretch;

  &-content {
    display: grid;
    place-items: center;
  }

  &-sidebar {
    display: grid;
    place-items: center;
  }
}
