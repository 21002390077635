@import "src/variables";

.VerifyTask {
  height: 100%;
  overflow-y: scroll;
  padding: 48px;

  &-form {
    width: 100%;
    max-width: 720px;
  }

  .label-column {
    width: 250px;
    text-align: right;
    padding-right: 8px;
  }

  .input-column {

  }

  .check-column {
    width: 120px;
    text-align: right;
    padding-right: 8px;
  }

  .check-input-column {
    width: 30px
  }

  .error-column {
    color: red;
    font-size: smaller;
  }
}
