.Schedule {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;

  &-scheduleTimeSection {
    display: flex;
    gap: 0 20px;
    flex-wrap: wrap;
  }

  &-scheduleEventTimeItem {
    display: flex;
    gap: 10px;
  }

  &-timeSelect {
    width: 80px;
  }

  &-dateSection {
    display: flex;
    gap: 10px;
  }

  &-datePicker {
    width: 120px;
  }

  &-deleteItemButton {
    margin-top: 3px;
  }

  &-addButton {
    margin-bottom: 24px;
    padding: 4px;

    & > span {
      text-decoration: underline;
    }
  }

  &-addDateButton {
    width: 110px;
  }
}