@import "src/variables";

.SignupPage {
  padding: 48px;

  &-form {
    width: 100%;
    max-width: 720px;
  }

  .label-column {
    width: 250px;
    text-align: right;
    padding-right: 8px;
  }

  .error-column {
    color: red;
    font-size: smaller;
  }
}
