@import "../../variables";

html, body {
  margin: 0;
  padding: 0;
  display: flex;
}

#root {
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: $gray4;
}

::-webkit-scrollbar-thumb {
  background: $gray5;
}

::-webkit-scrollbar-thumb:hover {
  background: $gray6;
}

::-webkit-scrollbar-corner {
  background: $gray4;
}

th {
  text-align: left;
}

html::-webkit-scrollbar-thumb {
  //background: blue;
}

body::-webkit-scrollbar-thumb {
  //background: yellow;
}

#root::-webkit-scrollbar-thumb {
  //background: green;
}

.ant-table-body {
  scrollbar-width: auto;
  scrollbar-color: auto;
}
