@import "src/variables";

.ant-btn {
  border-radius: 4px;
}

.ant-btn-circle {
  border-radius: 50%;
}

.clickpass-tabs-bar {
  padding-right: 8px;

  .ant-btn {
    color: $shade9;
    background: $shade3;
    border: 0;
    border-radius: 2px;

    &:hover {
      color: $shade9;
      background: $shade1;
    }

    &:active {
      color: $shade9;
    }
  }

  .ant-picker {
    color: $shade9;
    background: $shade3;
    border: 0;
    border-radius: 2px;
  }
}
