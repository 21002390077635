@import "src/variables";

.Menu {
  background: $shade4;
  min-height: 100%;

  a {
    color: $shade9;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }

  &-title {
    padding: 12px 16px;

    & h4 {
      margin: 8px 0 0 0;
    }
  }

  &-item {
    padding: 12px 24px;

    &-active {
      background: $shade3;
      font-weight: bold;
    }

    &:hover {
      background: $shade3;
    }
  }
}
