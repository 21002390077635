@import "src/variables";

.ProductsPage {
  min-height: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 250px 1fr;

  &-tableRow--inactive {
    background-color: lightyellow;
  }

  &-menu {
    min-height: 0;
    background: $shade3;
    display: grid;
    grid-template-rows: 1fr auto;

    &Items {
      overflow: auto;

      h3 {
        margin: 16px;
      }
    }
    &Buttons {
      margin: 8px
    }

    &Item {
      padding: 8px 16px;
      cursor: pointer;

      a {
        color: black;
        text-decoration: underline;
      }
    }

    & .ant-btn {
      width: 100%;
      margin-top: 4px;
    }
  }

  &-content {
    height: 100%;
    overflow: auto;
    padding: 16px;
  }

  &-header {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    justify-items: left;
    margin-bottom: 12px;

    &-title {
      margin: 0;
    }

    &-addProductBtn {
      justify-self: right;
    }
  }

  &-headerButtons {
    display: grid;
    grid-gap: 4px;
    grid-auto-flow: column;
  }
}
