.MultiTicketCalendarV1Categories {
  &-addCategoryButton {
    margin-bottom: 24px;
    padding: 4px;

    & > span {
      text-decoration: underline;
    }
  }
}

.MultiTicketCalendarV1Category {
  &-name {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-gap: 8px;
  }

  &-components {
    width: calc(100% - 32px);
    margin: 0 32px 24px 32px;

    th {
      font-size: 8pt;
    }
  }

  &-addComponent {
    margin-left: 32px;
  }
}