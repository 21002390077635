@import "./variables";

html,
body {
  width: 100%;
  height: 100%;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: Open Sans;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: $gray1;
  font-feature-settings: 'tnum';
}
