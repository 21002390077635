.MultiTicketCalendar {
  &-specificationSelect {
    padding-top: 10px;
  }

  &-specificationSelectLabel {
    margin-bottom: 10px;
  }

  &-radio {
    white-space: break-spaces;
  }
}
