.MultiTicketComponentProductFormModal {
  &-error {
    color: red;
  }

  &-categoryTable {
    width: 100%;

    & tbody td {
      vertical-align: top;
      padding-top: 10px;
    }
  }

  &-categoryName {
    padding-right: 20px;
  }

  &-categorySelect {
    width: 100%;
  }

  &-deleteCategoryButton {
    text-align: right;
  }
}
