.ChildPartners {
  height: 100%;
  overflow: auto;
  padding: 16px;

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-items: left;
    margin-bottom: 12px;
  }
  &-headerTitle {
    margin: 0;
  }
}
